<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>提现管理</template>
      <template v-slot:secondMenu>提现配置</template>
    </breadcrumb-nav>
    <el-card>
      <el-button
        type="primary"
        style="margin-bottom: 20px"
        @click="submit"
      >提交
      </el-button>
      <el-form ref="form" :model="form" label-width="150px">
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="微信提现" prop="wxa">
              <el-switch
                v-model="form.wxa"
                active-text="开启"
                inactive-text="关闭"
                :active-value=1
                :inactive-value=0
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付宝提现" prop="ali">
              <el-switch
                v-model="form.ali"
                active-text="开启"
                inactive-text="关闭"
                :active-value=1
                :inactive-value=0
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="24">
            <el-form-item label="提现档次" prop="values">
              <el-tag v-for="(item,i) in form.values" :key="i" closable @close="handleClose(i, item)">{{item}}</el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              ></el-input>
              <!-- 添加按鈕 -->
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput"
              >+ 添加</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="7">
            <el-form-item label="一天最大笔数">
              <el-input type="Number" v-model.number="form.count"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="一笔最小金额">
              <el-input type="Number" v-model.number="form.minValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="一天累计金额">
              <el-input type="Number" v-model.number="form.maxValue"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getConfigSetting, updateConfigSetting } from '../../network/notify'

const defaultWithdrawSetting = {
  // id: null,
  count: 0,
  maxValue: 0,
  minValue: 0,
  values: [],
  wxa: 0,
  ali: 0
}

export default {
  name: 'Setting',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      /* form: {
        count: 0,
        maxValue: 0,
        values: [],
        wxa: 0,
        ali: 0
      }, */
      form: Object.assign({}, defaultWithdrawSetting),
      inputVisible: false,
      inputValue: '',
      withdrawConfigType: 3
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    submit () {
      const compare = function (x, y) {
        if (x < y) {
          return -1
        } else if (x > y) {
          return 1
        } else {
          return 0
        }
      }
      this.form.values.sort(compare)
      console.log('withdraw submit form:', this.form)
      if (this.form.ali === 0 && this.form.wxa === 0) {
        return this.alertMessage('支付宝和微信提现至少开启一种', 'error')
      }
      if (this.form.values.length <= 0) {
        return this.alertMessage('请填写提现金额档次信息', 'error')
      }
      if (this.form.count <= 0) {
        return this.alertMessage('请设置一天提现笔数', 'error')
      }
      if (this.form.minValue <= 0) {
        return this.alertMessage('请设置一笔最小提现金额', 'error')
      }
      if (this.form.maxValue <= 0) {
        return this.alertMessage('请设置一天累计最大提现金额', 'error')
      }
      // return
      // eslint-disable-next-line no-unreachable
      updateConfigSetting({
        content: this.form,
        desc: '提现配置信息',
        type: this.withdrawConfigType
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改订单配置信息失败', 'error')
        }
        this.alertMessage('修改订单配置信息成功', 'success')
      })
    },
    handleClose (idx, value) {
      this.form.values.splice(idx, 1)
    },
    handleInputConfirm () {
      if (this.inputValue.trim() !== '' && !this.hasSameVal()) {
        const value = parseInt(this.inputValue.trim())
        this.form.values.push(value)
      }
      this.inputValue = ''
      this.inputVisible = false
    },
    hasSameVal () {
      const value = parseInt(this.inputValue.trim())
      // 属性值不能重复,这里也可以用some
      const result = this.form.values.every(
        (item) => item !== value
      )
      return !result
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    getDetail () {
      getConfigSetting({
        type: this.withdrawConfigType
      }).then(res => {
        console.log('get config setting:', res)
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取订单配置数据获取失败', 'error')
          return
        }
        this.form = result.data.content
        if (!this.form.values) {
          this.form = defaultWithdrawSetting
        }
        console.log('withdrawConfig is:', this.form)
      }, err => {
        console.log('get data err:', err)
      })
    }
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
